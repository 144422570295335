import Swiper from 'swiper';
import {Autoplay, Navigation} from 'swiper/modules';

export function initTestimonialsSwiper(query = document.querySelectorAll('.testimonials__list')) {
    for (let testimonials of query) {
        Swiper.use([Autoplay, Navigation]);
        new Swiper(testimonials, {
            slidesPerView: 1,
            spaceBetween: 20,
            loop: true,
            navigation: {
                nextEl: testimonials.querySelector('.swiper-button-next'),
                prevEl: testimonials.querySelector('.swiper-button-prev'),
            }
        });
    }
}