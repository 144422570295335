function checkCountUp(target, count){
    const position = target.getBoundingClientRect();
    let timeout = 3000;
    let i = 0;
    let multiplicator = 1;
    const duration = 1500;

    if(count > 100){
        multiplicator = 4

    }

    if(count > 1000){
        multiplicator = 19

    }

    if(count > 10000){
        multiplicator = 789
    }

    let intervals = Math.round(count / multiplicator);

    if((position.y > 0) && (position.y < window.outerHeight) && !target.classList.contains('counted')) {
        target.classList.add('counted');
        let inv = setInterval(function() {
            if(i < count) {
                i = i + multiplicator;
                if(i > count){
                    i = count;
                }
                target.innerHTML = i;
            }else{
                clearInterval(inv);
            }
        }, duration / intervals);
    }
}

export function countUp(target){
    let count = 0;

    if(target.dataset.countUp){
        count = parseInt(target.dataset.countUp);
    }

    window.addEventListener("load",function (e) {
        checkCountUp(target,count);
    });

    window.addEventListener("scroll",function (e) {
        checkCountUp(target,count);
    });

    window.addEventListener("orientationchange",function (e) {
        checkCountUp(target,count);
    });
}