import Inputmask from "inputmask";

export function initInputMaskDate(query = document.querySelectorAll('input[type="date"],.js-inputMasketDate')) {
    for(let input of query){
        if(input.closest(".infieldInputGroup")){
            input.closest(".infieldInputGroup").classList.add("is-focus");
        }
        input.setAttribute("type","text");
        input.setAttribute("placeholder","dd.mm.yyyy");
        Inputmask({
            alias:"datetime",
            inputFormat: "dd.mm.yyyy",
            outputFormat: "dd.mm.yyyy"
        }).mask(input);
    }
}