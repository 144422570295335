function checkStickyHelper(element) {
    const stickyHelper = document.createElement('div');
    let newStickyHelper;
    stickyHelper.classList.add('sticky-helper');
    newStickyHelper = element.insertAdjacentElement('beforebegin', stickyHelper);
    return newStickyHelper;
}

function checkSticky(element, stickyClass, start, generateStickyHelper = false) {
    let scrollPos = window.scrollY;
    let positionCSSElement = element.style.position;
    let heightElement = element.offsetHeight;
    let prevElement = element.previousElementSibling;
    let stickyHelper;

    if (scrollPos > 0) {
        element.classList.add(stickyClass);
        /* if (generateStickyHelper) {
            if (prevElement !== null) {
                if (positionCSSElement !== "fixed" && !prevElement.classList.contains('sticky-helper')) {
                    stickyHelper = checkStickyHelper(element);
                    stickyHelper.style.height = heightElement + "px";
                }
            } else {
                if (positionCSSElement !== "fixed") {
                    stickyHelper = checkStickyHelper(element);
                    stickyHelper.style.height = heightElement + "px";
                }
            }
        } */
    } else {
        element.classList.remove(stickyClass);
        /* if (prevElement !== null) {
            if (prevElement.classList.contains('sticky-helper')) {
                prevElement.remove();
            }
        } */
    }
}

export function stickyElement(element, generatePlaceholder = false) {
    if (element) {
        let stickyClass = "sticky";
        let elementBounding = element.getBoundingClientRect();
        let elementStyle = element.currentStyle || window.getComputedStyle(element);
        let start = window.scrollY + elementBounding.y + parseInt(elementStyle.marginTop);

        checkSticky(element, stickyClass, start, generatePlaceholder);

        window.addEventListener("scroll", function (e) {
            elementBounding = element.getBoundingClientRect();
            checkSticky(element, stickyClass, start, generatePlaceholder);
        });
    } else {
        console.error("cannot find sticky element");
    }
}

export function calculateStickyTop(stickyElement, offset = 0, headerArea) {

    let headerHeight;

    function calculateHeightAndResize(element) {
        headerHeight = headerArea.getBoundingClientRect().height + offset;
        element.style.top = headerHeight+'px';
    }

    if(stickyElement) {
        if(stickyElement.style.position !== 'sticky') {
            stickyElement.style.position = 'sticky';
        }
        calculateHeightAndResize(stickyElement);

        window.addEventListener('resize', () => {
            calculateHeightAndResize(stickyElement);
        });
    }
}