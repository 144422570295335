function isElementInViewport(element) {
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    return (
        rect.bottom >= 0 &&
        rect.top <= windowHeight * 0.5
    );
}
export function elementViewportScroll() {
    let targetElement = document.querySelector('.blueSlideIn');
    if (targetElement) {
        if (isElementInViewport(targetElement)) {
            targetElement.classList.add('inView');
        } else {
            targetElement.classList.remove('inView');
        }
    }
}

elementViewportScroll();
window.addEventListener('scroll', elementViewportScroll);