function hideOverlay(overlay,optionalBannerText = false){
    overlay.classList.add('hide');
    setTimeout(function() {
        overlay.style.display = 'none';
    }, 1200);

    if (optionalBannerText) {
        setTimeout(function() {
            optionalBannerText.classList.remove('temporaryHidden');
        }, 700);
    }

    document.cookie='hideTemporaryOverlay=true; max-age=604800; path=/';
}

export function hideTemporaryOverlay() {
    let overlay = document.querySelector('.temporaryOverlay');
    let optionalBannerText = document.querySelector('.banner--alternative .contentText');
    if (overlay) {
        if (optionalBannerText) {
            optionalBannerText.classList.add('temporaryHidden');
        }

        overlay.addEventListener('click', function() {
            hideOverlay(overlay,optionalBannerText);
        });

        let scrolling = false;

        window.addEventListener("mousewheel", function() {
            if (!scrolling) {
                scrolling = true;
                hideOverlay(overlay,optionalBannerText);
            }
        });

        setTimeout(function() {
            hideOverlay(overlay,optionalBannerText);
        }, 1500)
    }
}