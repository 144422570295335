export function setHeaderTopWhite() {
    let headerTopWhiteClass = document.querySelector('.setHeaderTopWhite');
    if (headerTopWhiteClass) {
        let switchHeaderTopToWhite = document.querySelector('.headerTop');

        if (switchHeaderTopToWhite) {
            switchHeaderTopToWhite.classList.add('switchToWhite');
        }
    }

    let headerTopLeftWhiteClass = document.querySelector('.setTopLeftToWhite');
    if (headerTopLeftWhiteClass) {
        let switchHeaderTopToWhite = document.querySelector('.headerTop');

        if (switchHeaderTopToWhite) {
            switchHeaderTopToWhite.classList.add('switchTopLeftToWhite');
        }
    }
}