import {getOptionsFromJsonString} from "../../../default/global/js/utilities/options";

export function stickyBox(el, options = {}) {

    let defaultOptions = {
        selectorPinArea: ".js-stickyArea",
        minWidth: GLOBALS.gridBreakpoints.md,
        offsetTop: 0
    }

    let elWidth = 0;

    options = {...defaultOptions, ...options};

    options = getOptionsFromJsonString(el.dataset.options, options);

    let pinArea = el.closest(options.selectorPinArea);

    function resetBox() {
        el.style.position = "";
        el.style.top = "";
        el.style.width = "";
        el.classList.remove('sticky');
    }

    function setBoxSticky() {
        el.style.position = "fixed";
        el.style.top = options.offsetTop + "px";
        el.style.width = elWidth + "px";
        el.classList.add('sticky');
    }

    function checkBox() {
        let elPosition = el.getBoundingClientRect();
        if (!el.classList.contains('sticky')) {
            elWidth = elPosition.width;
        }
        let pinAreaPosition = pinArea.getBoundingClientRect();
        if (elPosition.height < pinAreaPosition.height) {
            pinArea.style.minHeight = pinAreaPosition.height + "px";
            if ((pinAreaPosition.y - options.offsetTop) <= 0 && window.innerWidth >= options.minWidth) {
                setBoxSticky();
                let topPosition = options.offsetTop;
                let spacerBetweenBoxAndAreaEnd = (pinAreaPosition.height) + pinAreaPosition.top;
                if ((spacerBetweenBoxAndAreaEnd - options.offsetTop) <= elPosition.height) {
                    topPosition = pinAreaPosition.height + pinAreaPosition.top - elPosition.height;
                }
                el.style.top = (topPosition) + "px";
            } else {
                resetBox();
            }
        }
    }

    if (pinArea) {
        checkBox();
        window.addEventListener("scroll", checkBox);
        window.addEventListener("resize", checkBox);
        window.addEventListener("orientationchange", checkBox);
    } else {
        console.error("Box Area not found");
    }
}

export function stickyBoxActiveStates(){
    var stickyBar = document.querySelector('.stickyBar__menu');

    if (stickyBar) {
        var stickyBarHeight = stickyBar.offsetHeight;
        var windowTop = window.scrollY + stickyBarHeight/2;
        var stickyBarListElements = stickyBar.querySelectorAll('li');
        stickyBarListElements.forEach(function (listItem) {
            var thisListItem = listItem;
            var curHref = thisListItem.querySelector('a').getAttribute('href');
            var targetElement = document.querySelector(curHref);
            var offsetTopCurHref = targetElement.getBoundingClientRect().top + targetElement.offsetTop;

            if (windowTop > offsetTopCurHref) {
                var prevSiblings = getAllPreviousSiblings(thisListItem);
                var nextSiblings = getAllNextSiblings(thisListItem);

                prevSiblings.forEach(function (sibling) {
                    sibling.classList.remove('active');
                    sibling.classList.add('inactive');
                });

                nextSiblings.forEach(function (sibling) {
                    sibling.classList.remove('active');
                    sibling.classList.add('inactive');
                });

                thisListItem.classList.remove('inactive');
                thisListItem.classList.add('active');
            }
        });
    }

    function getAllPreviousSiblings(element) {
        var siblings = [];
        var previousSibling = element.previousElementSibling;

        while (previousSibling) {
            siblings.push(previousSibling);
            previousSibling = previousSibling.previousElementSibling;
        }

        return siblings;
    }

    function getAllNextSiblings(element) {
        var siblings = [];
        var nextSibling = element.nextElementSibling;

        while (nextSibling) {
            siblings.push(nextSibling);
            nextSibling = nextSibling.nextElementSibling;
        }

        return siblings;
    }
}