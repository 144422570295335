import {initTrustbars} from "../../../default/global/js/trustbar";
import {getChildrenClassForNestedItems} from "../../../default/global/js/nav";
import {closeFilterBox, initFilter} from "../../../default/global/js/filter";
import {initActionClicks, initFormSubmitChanges, initFormSubmitClicks} from "../../../default/global/js/actionClick";
import {focusInputGroups} from "../../../default/global/js/checkInputGroups";
import {stickyHeader} from "../../../default/global/js/stickyheader";
import hoverintent from "../../../default/global/js/hoverintent";
import {initSlideshows} from "../../../default/global/js/slideshow";
import {openFullscreenGallery} from "../../../default/global/js/fullscreengallery";
import {buildMobileMenu, hideMobileMenu, showMobileMenu} from "../../../default/global/js/mobilemenu";
import {excludeModal, triggerConfirmModalLink, triggerModal} from "../../../default/global/js/modal";
import {initToggleSlide} from "../../../default/global/js/toggle";
import {initItemlistSlider} from "../../../default/global/js/itemListSlider";
import {setSrcVideos} from "../../../default/global/js/video";
import {initAlternativeScrollbars, initScrollbars} from "./custom/scrollbar";
import {isTouchDevice} from "../../../default/global/js/touchDevice";
import {initCustomSelects} from "../../../default/global/js/customSelect";
import {initJumpmarks} from "../../../default/global/js/jumpmark";
import {initSearchItems} from "../../../default/global/js/searchItems";
import {lottieLoader} from "../../../default/global/js/lottieLoader";
import {initPasswordFields} from "../../../default/global/js/password";
import {trimInputValues} from "../../../default/global/js/formValidator";
import {initSlidecontents} from "../../../default/global/js/slidecontent";
import {initPhoneCountryFields} from "../../../default/global/js/phoneCountryField";
import {initInputBody} from "../../../default/global/js/inputfile";
import {initInputMaskDate} from "../../../default/global/js/inputMaskDate";
import {elementViewportScroll} from "./custom/elementViewportScroll";
import {replaceMobileCartButton} from "./custom/replaceMobileCartButton";
import {countUp} from "../../../default/global/js/countUp";
import {initHistorySwiper} from "../../../spGlobal/js/collections/history";
import {textSwitcher} from "../../../spGlobal/js/cms/textSwitcher";
import {stickyBox, stickyBoxActiveStates} from "../../../spGlobal/js/cms/stickyBox.js";
import {initImageGallery} from "../../../spglobal/js/cms/imageGallery";
import {initTestimonialsSwiper} from "../../../spGlobal/js/collections/testimonials";
import {setHeaderTopWhite} from "../../../spGlobal/js/cms/setHeaderTopWhite";
import {hideTemporaryOverlay} from "./custom/hideTemporaryOverlay";
import {disableContextMenuImages} from "../../../spGlobal/js/disableContextMenuImages";
import {triggerFilegalleryFilter} from "../../../spGlobal/js/filegalleryFilter";
import {initFilterPersonsCollection} from "../../../spGlobal/js/collections/persons";
import {sortSelectOptions} from "../../../spGlobal/js/sortSelectOptions";
import {initMasonryGallery} from "../../../spGlobal/js/cms/mansonry";

window.addEventListener("load", (event) => {
    const mobileMenu = document.querySelector('.mobileMenu');
    const mobileMenuToggle = document.getElementById("js-toggleNavigation");
    const overlay = document.getElementById("overlay");
    const IsTouchDevice = isTouchDevice();
    const stickyHeaderArea = document.querySelector('#header');

    let breakPointSM = 1024;
    let sendFavoriteByJS = false;
    let sendItemToBasketByJS = false;
    let updateBasketByJS = false;

    if (typeof GLOBALS !== "undefined") {
        breakPointSM = GLOBALS.gridBreakpoints.sm;
        sendFavoriteByJS = GLOBALS.sendFavoriteByJS;
        sendItemToBasketByJS = GLOBALS.sendItemToBasketByJS;
        updateBasketByJS = GLOBALS.updateBasketByJS;
    }

    if (mobileMenu && mobileMenuToggle && overlay) {
        const mobileMenuClose = mobileMenu.querySelector(".mobileMenu__close");

        buildMobileMenu(mobileMenu);

        mobileMenuToggle.addEventListener("click", function () {
            if (mobileMenuToggle.classList.contains('is-active')) {
                hideMobileMenu(mobileMenu, overlay, mobileMenuToggle);
            } else {
                showMobileMenu(mobileMenu, overlay, mobileMenuToggle);
            }
        })

        overlay.addEventListener("click", function () {
            hideMobileMenu(mobileMenu, overlay, mobileMenuToggle);
            for (let filterbox of document.querySelectorAll('.filterBox')) {
                closeFilterBox(filterbox);
            }
        });
    }

    initTrustbars();

    initFilter();

    initActionClicks();

    initFormSubmitClicks();

    initFormSubmitChanges();

    focusInputGroups();

    trimInputValues();

    initInputBody();

    initPasswordFields();

    initPhoneCountryFields();

    elementViewportScroll();

    replaceMobileCartButton();

    initHistorySwiper();

    initTestimonialsSwiper();

    textSwitcher();

    setHeaderTopWhite();

    hideTemporaryOverlay();

    // currentJobCounter();

    let stickyElement = document.querySelector('.stickyBar__menu');
    if (stickyElement) {
        let stickyElementInit = ((window.outerHeight - stickyElement.getBoundingClientRect().height) / 2);
        setTimeout(function () {
            stickyBox(stickyElement, {
                selectorPinArea: ".stickyBar__wrapper",
                offsetTop: stickyElementInit
            });
        }, 200);
    }

    stickyBoxActiveStates();
    window.addEventListener('scroll', stickyBoxActiveStates);

    stickyHeader(stickyHeaderArea);

    for (let el of document.querySelectorAll("[data-count-up]")) {
        countUp(el);
    }

    if (window.outerWidth < breakPointSM) {
        for (let parent of document.querySelectorAll('.navgroup > ul > li')) {
            getChildrenClassForNestedItems(parent);
        }
    }

    triggerModal();
    excludeModal();
    triggerConfirmModalLink();

    initToggleSlide();

    if (!IsTouchDevice) {
        for (let el of document.querySelectorAll('.itembox,.iconbarItem')) {
            hoverintent(el);
        }
    }

    for (let el of document.querySelectorAll('.js-mainNavigation > ul > li')) {
        hoverintent(el);
    }

    setSrcVideos();

    initSlideshows();

    initImageGallery();

    initScrollbars();

    initAlternativeScrollbars();

    initSlidecontents();

    initItemlistSlider(document.querySelectorAll('.itemboxList.swiper'), {
        breakpointItems: {
            xs: 1.7,
            sm: 3,
            md: 4,
            lg: 4,
            xlg: 5
        }
    });

    initCustomSelects();

    initSearchItems(document.querySelectorAll('.js-searchInput'));

    let offsetTopJumpmarks = 0;
    if (stickyHeaderArea) {
        offsetTopJumpmarks = stickyHeaderArea.clientHeight;
    }

    if (typeof GLOBALS !== "undefined") {
        if (GLOBALS.offsetTopJumpmarks) {
            offsetTopJumpmarks += GLOBALS.offsetTopJumpmarks;
        }
    }

    initJumpmarks(offsetTopJumpmarks);

    for (let item of document.querySelectorAll(".js-openGallery")) {
        item.addEventListener('click', function (event) {
            openFullscreenGallery(item);
        });
    }

    let offsetTopStickyBox = 30;
    if (stickyHeaderArea) {
        offsetTopStickyBox += stickyHeaderArea.clientHeight;
    }

    for (let pinBox of document.querySelectorAll(".js-stickyBox")) {
        stickyBox(pinBox, {
            offsetTop: offsetTopStickyBox
        });
    }
    lottieLoader();

    initInputMaskDate();

    disableContextMenuImages();

    triggerFilegalleryFilter();

    initFilterPersonsCollection();

    for(let select of document.querySelectorAll(".js-sortByAlphabet")){
        sortSelectOptions(select);
    }

    initMasonryGallery();
});