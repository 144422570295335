import Swiper from 'swiper';
import {Autoplay, Navigation, Pagination, Scrollbar} from 'swiper/modules';

export function initItemlistSlider(query = document.querySelectorAll('.itemboxList.swiper')) {
    Swiper.use([Autoplay, Navigation, Scrollbar]);
    for (let itemlist of query) {

        Swiper.use([Autoplay, Navigation, Pagination]);
        new Swiper(itemlist, {
            loop: false,
            speed: 300,
            spaceBetween: 24,
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            autoHeight: false,
            autoplay: false,
            rewind: true,
            navigation: {
                nextEl: itemlist.querySelector('.swiper-button-next'),
                prevEl: itemlist.querySelector('.swiper-button-prev'),
            },
            scrollbar: {
                el: ".swiper-scrollbar",
                hide: false,
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.55,
                    slidesOffsetAfter: 40
                },
                600: {
                    slidesPerView: 2.55,
                    slidesOffsetAfter: 40
                },
                1024: {
                    slidesPerView: 3.55,
                    slidesOffsetAfter: 40
                },
                1350: {
                    slidesPerView: 4.55,
                    slidesOffsetAfter: 40
                },
                1730: {
                    slidesPerView: 4.55,
                    slidesOffsetAfter: 200
                }
            }
        });
    }
}