export function sortSelectOptions(selectElement) {
    let options = selectElement.options;
    let arr = [];
    let allOption = null;

    // Separate asterisk option from others
    for (let i = 0; i < options.length; i++) {
        if (options[i].value === "*") {
            allOption = {text: options[i].text, value: options[i].value};
        } else {
            arr.push({text: options[i].text, value: options[i].value});
        }
    }

    // Sort non-asterisk options
    arr.sort(function(a, b) {
        return a.text.localeCompare(b.text);
    });

    // Place asterisk option at the beginning
    if (allOption) {
        arr.unshift(allOption);
    }

    // Update options in select element
    for (let j = 0; j < options.length; j++) {
        options[j].text = arr[j].text;
        options[j].value = arr[j].value;
    }
}
