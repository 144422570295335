import {XhrService} from "../../default/global/ts/xhr.service";

function saveSelectedCategoryInSession(value){
    let formData = new FormData();
    formData.append("sid",document.body.dataset.sessionId);
    formData.append("selectedCategory",value);
    fetch('/module/dcshop/session.php', {
        method: 'POST',
        body: formData
    })
        .then(response => response.text())
        .then(data => {
            console.log('Session saved');
        })
        .catch(error => {
            console.error('Fehler:', error);
        });
}

export function triggerFilegalleryFilter(nodes = document.querySelectorAll(".js-filegalleryFilter")) {
    nodes.forEach(node => {
        const filterForm = node.querySelector("form.filegalleryFilter")
        const list = node.querySelector('.filegallery');

        if(filterForm && list){
            filterForm.addEventListener('change', e => {
                const checked = filterForm.querySelector(':checked')
                const category = checked.value;
                saveSelectedCategoryInSession(category);
                if (category === 'all') {
                    list.classList.remove('filegallery--filter-active')
                    list.querySelectorAll(`.filegalleryItem.active`).forEach(item => {
                        item.classList.remove('active')
                    })
                }
                else {
                    list.classList.add('filegallery--filter-active')
                    list.querySelectorAll(`.filegalleryItem.active`).forEach(item => {
                        item.classList.remove('active')
                    })
                    list.querySelectorAll(`.filegalleryItem[data-category='${category}']`).forEach(item => {
                        item.classList.add('active')
                    })
                }
            })
        }
    })
}

function filegalleryFilter(category, beforeSend, successSend, errorSend) {

    if (typeof beforeSend !== 'undefined') {
        beforeSend();
    }

    XhrService.generalAjaxCall('filter_filegallery', [
        category
    ], 'POST', 'json').then(res => {
        if (typeof successSend !== 'undefined') {
            successSend(res);
        }
    }, err => {
        console.error(err);
        if (typeof errorSend !== 'undefined') {
            errorSend();
        }
    })
}