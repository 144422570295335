let textVariables = ['den Holzbau.', 'Solar.', 'den Marinebedarf.', 'den Dach- und Fassadenbau.'];
let textVariablesIndex = 0;

export function textSwitcher() {
    let textSwitchElementsArray = document.querySelectorAll('.textSwitch');
    if (textSwitchElementsArray) {
        textSwitchElementsArray.forEach((textSwitchElement) => {
            setInterval(function() {
                textSwitchElement.textContent = textVariables[textVariablesIndex];
                textVariablesIndex = (textVariablesIndex < textVariables.length - 1) ? textVariablesIndex + 1 : 0;
            }, 2500);
        })
    }
}