import {loadScript} from "../../../default/global/js/utilities/loadScript";

export function initMasonryGallery(){
    let galleryWrapper = document.querySelectorAll('.imageGalleryMasonry');
    if(galleryWrapper.length > 0){
        loadScript('/plugins/isotope/isotope.pkgd.min.js').then(() => {
            loadScript('/plugins/isotope/imagesloaded.pkgd.min.js').then(() => {
                for(let gallery of galleryWrapper){
                    gallery.classList.add("loaded");
                    var iso = new Isotope(gallery,{
                        itemSelector: ".imageGalleryMasonryItem"
                    });

                    iso.imagesLoaded().progress( function() {
                        iso.isotope('layout');
                    });
                }
            });
        });
    }
}
