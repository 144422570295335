function getSelectedValues(filter) {
    const arrValues = [];

    for (let filterItem of filter.querySelectorAll("select:not(.notFilter)")) {
        if (filterItem.value !== "*") {
            let filterDataset = filterItem.dataset.filter;
            arrValues.push(filterDataset + "=" +filterItem.value);
        }
    }

    return arrValues;
}

function getSelectedTagValues(tags) {
    const arrValues = [];

    for (let tag of tags) {
        if(tag.classList.contains("is-active")){
            let filterKey = tag.dataset.filter;
            let filterValue = tag.dataset.filterValue;
            if(tag.nodeName === "SELECT"){
                filterKey = tag.name;
                filterValue = tag.value;
            }
            if(filterValue !== "*"){
                arrValues.push(filterKey + "=" +filterValue);
            }
        }
    }

    return arrValues;
}

function filterEntries(collectionList,arrValues) {
    for (let entry of collectionList.querySelectorAll(".collectionListEntry")) {
        let showEntry = true;
        let arrFilterValuesByEntry = [];
        const filterValues = entry.dataset.filterValues.split(';');

        for (let filterValue of filterValues){
            let indexSplit = filterValue.indexOf("=");
            let filterGroup = filterValue.substring(0,indexSplit);
            let filterValuesToSplit = filterValue.substring(indexSplit+1,filterValue.length);
            if(filterGroup !== ""){
                if(!arrFilterValuesByEntry[filterGroup]){
                    arrFilterValuesByEntry.push(filterGroup);
                }
                let filterValueSplit = filterValuesToSplit.split(",");
                arrFilterValuesByEntry[filterGroup] = [...filterValueSplit];
            }
        }
        for (let selectedFilter of arrValues) {
            let indexSplit = selectedFilter.indexOf("=");
            let filterGroup = selectedFilter.substring(0,indexSplit);
            let filterValuesToSplit = selectedFilter.substring(indexSplit+1,selectedFilter.length);
            let arrFilterValuesByGroup = arrFilterValuesByEntry[filterGroup];
            if(arrFilterValuesByGroup){
                for (let selectedValue of filterValuesToSplit.split(",")){
                    if (!arrFilterValuesByGroup.includes(selectedValue)) {
                        showEntry = false;
                    }
                }
            }else{
                showEntry = false;
            }
        }
        if (showEntry) {
            entry.style.display = '';
            entry.classList.remove('hidden');
        } else {
            entry.style.display = 'none';
            entry.classList.add('hidden');
        }

    }
}

function filterEntriesBySearch(collectionList,value = "") {
    for (let entry of collectionList.querySelectorAll(".collectionListEntry:not(.hidden)")) {
        let entryText = entry.innerText.toLowerCase();
        entry.style.display = 'none';

        if(value === ""){
            entry.style.display = '';
        }else{
            if(entryText.includes(value.toLowerCase())){
                entry.style.display = '';
            }
        }
    }
}

function splitCommaOptions(filter) {
    for (let item of filter) {
        let value = item.value;
        if(value.includes(",")){
            let split = value.split(",");
            for(let splitItem of split){
                let newOpt = document.createElement("option");
                newOpt.value = splitItem;
                newOpt.innerHTML = splitItem;
                item.after(newOpt);
            }
            item.remove();
        }
    }
}

function removeDuplicateValues(filter) {
    const arrFilters = [];

    for (let item of filter) {
        let value = item.value.trim();
        if(value !== ''){
            if (arrFilters.includes(value)) {
                item.remove();
            } else {
                arrFilters.push(value);
            }
        } else {
            item.remove();
        }
    }
}

export function initLiveFilterCollection(collection) {

    let filterSelector = collection.dataset.filter;
    let filter = document.querySelector('.collectionFilterJobs');
    if (filter) {

        let allSelect = filter.querySelectorAll('select:not(.notFilter)');
        let filterSearch = filter.querySelector('.filterJobsSearch');
        for (let select of allSelect) {
            let options = select.querySelectorAll('option');
            splitCommaOptions(options);
            options = select.querySelectorAll('option');
            removeDuplicateValues(options);

            select.addEventListener("change", function (e) {
                let selectedValues = getSelectedValues(filter);

                filterEntries(collection, selectedValues);
                if (filterSearch) {
                    filterSearch.value = "";
                }

                e.preventDefault();
            });
        }
        if (filterSearch) {
            filterSearch.addEventListener("keyup", function (e) {
                filterEntriesBySearch(collection, filterSearch.value);
                e.preventDefault();
            });
        }
    }
}

function deactivateOtherFilters(tags,activeTag){
    for (let tag of tags){
        if(tag !== activeTag){
            tag.classList.remove("is-active");
        }
    }
}

export function initFilterPersonsCollection(query = document.querySelectorAll('.js-updateCollectionArea')) {
    let single = false;
    for(let filterArea of query){
        let collection = filterArea.querySelector(".collection");
        let tags = filterArea.querySelectorAll(".js-updateCollection");
        if(tags && collection){
            for(let tag of tags){
                let eventType = "click";
                if(tag.nodeName === "SELECT"){
                    eventType = "change";
                }
                tag.addEventListener(eventType,function (e) {
                    let single = false;
                    if(tag.classList.contains("js-updateCollection--single")){
                        single = true;
                    }
                    if(eventType === "click"){
                        tag.classList.toggle("is-active");
                    }else{
                        if(tag.value !== "*"){
                            tag.classList.add("is-active");
                        }else{
                            tag.classList.remove("is-active");
                        }
                    }
                    if(single){
                        deactivateOtherFilters(tag.closest('.tags').querySelectorAll('.js-updateCollection'),tag);
                    }
                    let selectedValues = getSelectedTagValues(tags);
                    filterEntries(collection,selectedValues);
                    e.preventDefault();
                });
            }
        }
    }
}