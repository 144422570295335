import Swiper from "swiper";
import {Autoplay, Navigation, Scrollbar} from 'swiper/modules';

export function initHistorySwiper(query = document.querySelectorAll('.history__list')) {
    let autoplay = false;
    if(window.innerWidth < GLOBALS.gridBreakpoints.sm){
        autoplay = {
            delay: 2000,
        }
    }
    for (let slideshow of query) {
        Swiper.use([Autoplay, Navigation, Scrollbar]);
        new Swiper(slideshow, {
            slidesPerView: "auto",
            spaceBetween: 20,
            navigation: {
                nextEl: slideshow.querySelector('.swiper-button-next'),
                prevEl: slideshow.querySelector('.swiper-button-prev'),
            },
            autoplay: autoplay,
            scrollbar: {
                el: ".swiper-scrollbar",
                hide: false,
            },
            breakpoints: {
                768: {
                    spaceBetween: 40
                },
                1500: {
                    spaceBetween: 64,
                },
                1850: {
                    spaceBetween: 64,
                    slidesOffsetAfter: 100
                }
            }
        });
    }
}